import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { updateRequesterLoading } from './redux/actions';
import { getRequesterLoading, getRequesterList} from './redux/selectors';
import * as API from './api';
import moment from "moment";

class RequesterList extends PureComponent
{
  componentDidMount() {
    const {updateLoading, device, callReloadRequesters} = this.props;
    updateLoading(true);
    callReloadRequesters(device).finally(() => updateLoading(false));
  }

  render() {
    const {loading, requesters} = this.props;
    return (loading ?
      <div className="align-items-center d-flex flex-column justify-content-center vh-100">
        <div className="spinner-border text-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div> :
      <div className="table-responsive-sm"><table className="table word-nowrap-sm">
      <thead className="thead-light">
      <tr>
        <th scope="col">#</th>
        <th scope="col">Date</th>
        <th scope="col">Login User</th>
        <th scope="col">Machine</th>
        <th scope="col">IP</th>
        <th scope="col">Address</th>
      </tr>
      </thead>
      <tbody>{requesters.map((requester, idx) => {
          const date = moment(requester.date).format('llll');
          return (<tr key={requester.date}>
            <td>{idx+1}</td>
            <td>{date}</td>
            <td>{requester.login_user}</td>
            <td>{requester.machine}</td>
            <td>{requester.ip}</td>
            <td>{requester.address}</td>
          </tr>);
        })
      }</tbody>
    </table></div>);
  }
}
function mapStateToProps(state, {match}) {
  const device = decodeURIComponent(match.params.device);
  let requesters = getRequesterList(state);
  requesters = device ? requesters.filter((req) => req.device === device) : [];
  const loading = getRequesterLoading(state)
  return {device, requesters, loading};
}
const actionsFactory = {
  updateLoading: updateRequesterLoading,
  callReloadRequesters: API.reloadRequesters,
};
export default withRouter(connect(mapStateToProps, actionsFactory)(RequesterList));
