import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Route, withRouter, Link } from 'react-router-dom';
import moment from 'moment';
import { updateUserListView, updateUserFormView, updateUserConfirmView } from './redux/actions';
import {
  getVersion, getUserList, getUserListViewState, getUserFormViewState, makeUserSelector, getUserConfirmViewState
} from './redux/selectors';
import * as API from './api';
import LogListComponent from './Logging';
import RequesterListComponent from './Requester';

const Product_VUE = 0x0;
const Product_MS = 0x1;
const Product_PeopleCert = (0x1) << 1;
const Product_Prometric = (0x1) << 2;

class UserForm extends PureComponent {
  constructor(props) {
    super(props);
    this.nowDay = moment().format('YYYY-MM-DD');
    this.expireDay = moment().add(1, 'year').format('YYYY-MM-DD');
  }

  componentDidMount() {
    const {user, updateUserFormView} = this.props;
    updateUserFormView({formData: {
      'status': 1,
      'create_date': this.nowDay,
      'expire_date': this.expireDay,
      'remark': '',
      'email':'',
      ...user
    }});
  }

  onSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const {user, formData, callCreateUser, callUpdateUser, history} = this.props;
    const data = {...user, ...formData};
    data.name = data.name && data.name.trim();
    const res = !!user ? callUpdateUser(data) : callCreateUser(data);
    res.then(() => history.push('/licence'));
  };

  onChange = (event) => {
    let name = event.target.name;
    const {formData, updateUserFormView} = this.props;
    let value = event.target.value;
    if (name === 'status') {
      value = event.target.checked ? 1 : 0;
    } else if (name === 'auto_enable') {
      value = event.target.checked ? 1 : 0;
    } else if (name === 'type-ms') {
      name = 'type';
      value = event.target.checked ? formData.type | Product_MS : formData.type & ~Product_MS;
    } else if (name === 'type-peoplecert') {
      name = 'type';
      value = event.target.checked ? formData.type | Product_PeopleCert : formData.type & ~Product_PeopleCert;
    } else if (name === 'type-prometric') {
      name = 'type';
      value = event.target.checked ? formData.type | Product_Prometric : formData.type & ~Product_Prometric;
    } else if (name === 'limit') {
      value *= 1;
    }
    updateUserFormView({'formData': {...formData, [name]: value}})
  };

  onCancel = () => this.props.history.goBack();

  render() {
    const {user, formData: data} = this.props;
    const nameEditable = !!user;
    const checked = data.status === 1;
    const auto_checked = data.auto_enable === 1;
    return (<div className="row no-gutters"><div className="card user-card col-lg-6 col-sm-8">
      <div className="card-header">
        User Information
      </div>
      <div className="card-body">
        <form onSubmit={this.onSubmit}>
          <div className="form-group">
            <label htmlFor="nameInput">Name</label>
            <input type="text" {...(nameEditable && {readOnly: true})} className="form-control"
                   id="nameInput" onChange={this.onChange} name="name" value={data.name || ''} required
                   autoCapitalize="none" autoCorrect="off" autoComplete="off" />
          </div>
          <div className="form-group">
            <label htmlFor="emailInput">Email</label>
            <input type="email"  className="form-control" id="emailInput" onChange={this.onChange}
                   name="email" value={data.email || ''} autoCapitalize="none" autoCorrect="off" autoComplete="off" />
          </div>
          <div className="form-group">
            <label htmlFor="tokenReadonly">Token</label>
            <input type="text" readOnly className="form-control" id="tokenReadonly" value={data.token || ''} />
          </div>
          <div className="form-group">
            <label htmlFor="startInput">Start Date</label>
            <input type="date" className="form-control" id="startInput" onChange={this.onChange}
                   name="create_date" value={data.create_date || ''} required />
          </div>
          <div className="form-group">
            <label htmlFor="endInput">Expire Date</label>
            <input type="date" className="form-control" id="endInput" onChange={this.onChange}
                   name="expire_date" value={data.expire_date || ''} required />
          </div>
          <div className="form-group">
            <label htmlFor="limitInput">Limit</label>
            <input type="number" className="form-control" id="limitInput" onChange={this.onChange}
                   name="limit" value={data.limit} required />
          </div>
          <div className="form-group">
            <label htmlFor="usedInput">Used</label>
            <input type="number" readOnly className="form-control" id="usedInput" value={data.used} />
          </div>
          <div className="form-check form-check-inline">
            <input className="form-check-input" type="checkbox" id="checkVUE" checked disabled />
              <label className="form-check-label" htmlFor="checkVUE">VUE</label>
          </div>
          <div className="form-check form-check-inline">
            <input className="form-check-input" type="checkbox" id="checkMS" onChange={this.onChange}
                   name="type-ms" checked={(data.type & Product_MS) === Product_MS} />
            <label className="form-check-label" htmlFor="checkMS">MS</label>
          </div>
          <div className="form-check form-check-inline">
            <input className="form-check-input" type="checkbox" id="checkPeopleCert" onChange={this.onChange}
                   name="type-peoplecert" checked={(data.type & Product_PeopleCert) === Product_PeopleCert} />
            <label className="form-check-label" htmlFor="checkPeopleCert">PeopleCert</label>
          </div>
          <div className="form-check form-check-inline">
            <input className="form-check-input" type="checkbox" id="checkPrometric" onChange={this.onChange}
                   name="type-prometric" checked={(data.type & Product_Prometric) === Product_Prometric} />
            <label className="form-check-label" htmlFor="checkPrometric">Prometric</label>
          </div>
          <div className="form-group">
            <label htmlFor="remarkInput">Remark</label>
            <textarea rows={2} className="form-control" id="remarkInput"
                   onChange={this.onChange} name="remark" value={data.remark || ''} />
          </div>
          <div className="form-group">
            <div className="custom-control custom-switch">
              <input type="checkbox" className="custom-control-input" onChange={this.onChange}
                     name="status" {...{checked}} id="enabledCheckbox" />
              <label className="custom-control-label" htmlFor="enabledCheckbox">Enabled</label>
            </div>
          </div>
          <div className="form-group">
            <div className="custom-control custom-switch">
              <input type="checkbox" className="custom-control-input" onChange={this.onChange}
                     name="auto_enable" checked={auto_checked} id="autoCheckbox" />
              <label className="custom-control-label" htmlFor="autoCheckbox">Auto Key</label>
            </div>
          </div>
          <div>
            <button type="submit" className="btn btn-primary mr-2">Save</button>
            <button type="button" className="btn btn-outline-secondary" onClick={this.onCancel}>Cancel</button>
          </div>
        </form>
      </div>
    </div></div>);
  }
}
function mapFormStateToPropsFactory() {
  const selectUser = makeUserSelector();
  return function (state, {match}) {
    const name = decodeURIComponent(match.params.username);
    const user = selectUser(state)(name);
    const {formData} = getUserFormViewState(state);
    return {user, formData};
  };
}
const actionsFormFactory = {
  updateUserFormView,
  callCreateUser: API.createUser,
  callUpdateUser: API.updateUser,
};
const UserFormComponent = withRouter(connect(mapFormStateToPropsFactory, actionsFormFactory)(UserForm));

class Version extends PureComponent {
  componentDidMount() {
    const {callGetVersion} = this.props;
    callGetVersion();
  }

  onEdit = () => {
    const {version, updateUserListView} = this.props;
    updateUserListView({version_editing: true, version_value: version});
  };

  onSave = () => {
    const {
      version_value: value,
      callSetVersion,
      updateUserListView
    } = this.props;
    callSetVersion(value).then(() => updateUserListView({version_editing: false}));
  };

  onCancel = () => {
    const {version, updateUserListView} = this.props;
    updateUserListView({version_editing: false, version_value: version});
  };

  onChange = (event) => this.props.updateUserListView({version_value: event.target.value});

  render() {
    const {version, version_value: value, version_editing: editing} = this.props;
    return (<div className="d-flex justify-content-start flex-nowrap">
      <form>
        <div className="form-group row">
          <label htmlFor="inputVersion" className="col-sm-4 col-form-label text-nowrap font-weight-bolder">
            Version:
          </label>
          <div className="col-sm-8">
            {editing
              ? <input type="text" required className="form-control" id="inputVersion" value={value} onChange={this.onChange} />
              : <input type="text" readOnly className="form-control-plaintext" id="inputVersion" value={version} />
            }
          </div>
        </div>
      </form>
      <div className="form-group">
        {editing ? <div>
            <button className="btn btn-link" onClick={this.onSave}>Save</button>
            <button className="btn btn-link" onClick={this.onCancel}>Cancel</button>
          </div>
          : <button className="btn btn-link" onClick={this.onEdit}>Edit</button>}
      </div>
    </div>);
  }
}

class ConfirmToken extends PureComponent {
  componentWillUnmount() {
    this.props.updateUserConfirmView({
      loading1: false,
      loading2: false,
      loading3: false,
    });
  }

  onContinue =() => {
    const {user, callUpdateToken, history, updateUserConfirmView} = this.props;
    updateUserConfirmView({loading1: true});
    callUpdateToken(user).then(() => history.push('/licence'))
      .finally(() => updateUserConfirmView({loading1: false}));
  };

  onBack = () => this.props.history.goBack();

  onSendNow = () => {
    const {user, history, updateUserConfirmView} = this.props;
    updateUserConfirmView({loading2: true});
    API.SendTokenMail(user.name, 'now').then(() => history.push('/licence'))
      .finally(() => updateUserConfirmView({loading2: false}));
  };

  onSendNext = () => {
    const {user, history, updateUserConfirmView} = this.props;
    updateUserConfirmView({loading3: true});
    API.SendTokenMail(user.name, 'auto').then(() => history.push('/licence'))
      .finally(() => updateUserConfirmView({loading3: false}));
  };

  render() {
    const {loading1, loading2, loading3} = this.props;
    return (<div className="card text-center confirm-card vertical-center">
      <div className="card-header">
        <p className="card-title">
          <i className="far fa-6x fa-question-circle text-warning" />
        </p>
      </div>
      <div className="card-body">
        <div className="card"><div className="card-body">
          <h5 className="card-title">Create a new token, the old token will be obsolete!</h5>
          <div className="mt-5">
            <button className="btn btn-primary mr-3" onClick={this.onContinue}>
              {loading1 && <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true" />}
              Create
            </button>
            <button className="btn btn-outline-secondary" onClick={this.onBack}>Back</button>
          </div>
        </div></div>

        <div className="card-deck mt-4">
        <div className="card"><div className="card-body">
          <h5 className="card-title">Send the current token to user</h5>
          <div className="mt-5">
            <button className="btn btn-outline-info mr-3" onClick={this.onSendNow}>
              {loading2 && <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true" />}
              Send
            </button>
          </div>
        </div></div>

        <div className="card"><div className="card-body">
          <h5 className="card-title">Send the next month token to user</h5>
          <div className="mt-5">
            <button className="btn btn-outline-success mr-3" onClick={this.onSendNext}>
              {loading3 && <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true" />}
              Send
            </button>
          </div>
        </div></div></div>
      </div>
    </div>);
  }
}
function mapUserStateToPropsFactory() {
  const selectUser = makeUserSelector();
  return function (state, {match}) {
    const name = decodeURIComponent(match.params.username);
    const user = selectUser(state)(name);
    const {loading1, loading2, loading3} = getUserConfirmViewState(state);
    return {user, loading1, loading2, loading3};
  };
}
const actionsUserFactory = {
  updateUserConfirmView,
  callUpdateToken: API.updateUserToken,
};
const ConfirmTokenComponent = withRouter(connect(mapUserStateToPropsFactory, actionsUserFactory)(ConfirmToken));

class UserList extends PureComponent {
  componentDidMount() {
    const {updateUserListView, callReloadUsers} = this.props;
    updateUserListView({loading: true});
    callReloadUsers().finally(() => updateUserListView({loading: false}));
  }

  convertTypeToProducts(type) {
    const products = [];
    if ((type & Product_VUE) === Product_VUE) products.push('VUE');
    if ((type & Product_MS) === Product_MS) products.push('MS');
    if ((type & Product_PeopleCert) === Product_PeopleCert) products.push('PeopleCert');
    if ((type & Product_Prometric) === Product_Prometric) products.push('Prometric');
    return products;
  }

  onViewDisabled = (event) => {
    const {updateUserListView} = this.props;
    const view_disabled = event.target.checked;
    updateUserListView({view_disabled});
  };

  render() {
    const {loading, users, view_disabled} = this.props;
    return (<div className="card">
      <div className="card-header">
        <Link className="btn btn-primary" to="/licence/user">
          <i className="fas fa-plus-circle mr-2" />
          High+ User
        </Link>
        <Link className="btn btn-outline-danger float-right" to="/licence/logs/user/__Internal__Anonymous__User__">
          Suspicious Activity
        </Link>
      </div>
      <div className="card-body">
        <div className="d-flex justify-content-between">
          <Version {...this.props} />
          <div className="custom-control custom-switch">
            <input type="checkbox" className="custom-control-input" id="viewSwitch"
                   checked={view_disabled} onChange={this.onViewDisabled} />
            <label className="custom-control-label" htmlFor="viewSwitch">View disabled users</label>
          </div>
        </div>
        {loading ?
          <div className="align-items-center d-flex flex-column justify-content-center vh-100">
            <div className="spinner-border text-primary" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div> :
          <div className="table-responsive">
            <table className="table table-sm word-nowrap-sm">
              <thead className="thead-light">
              <tr>
                <th scope="col">#</th>
                <th scope="col">Name</th>
                <th scope="col">Email</th>
                <th scope="col">Token</th>
                <th scope="col">Start</th>
                <th scope="col">Expire</th>
                <th scope="col">Enabled</th>
                <th scope="col">Products</th>
                <th scope="col">Machines</th>
                <th scope="col">Remark</th>
                <th scope="col">Activities</th>
              </tr>
              </thead>
              <tbody>{users.filter((user) => view_disabled ? true : user.status === 1).map((user, idx) => {
                return (<tr key={user.name}>
                  <td>{idx+1}</td>
                  <td>
                    <Link className="btn btn-link p-0 border-0 align-baseline text-decoration-none" to={`/licence/user/${user.name}`}>
                      {user.name}
                    </Link>
                  </td>
                  <td>{user.email}</td>
                  <td>
                  <span className="text-warning">
                    <Link className="text-decoration-none text-reset border-0 p-0 align-baseline ml-2" to={`/licence/token/${user.name}`}>
                      {user.token}
                    </Link>
                  </span>
                  </td>
                  <td>{user.create_date}</td>
                  <td>{user.expire_date}</td>
                  <td>
                    {user.status === 1
                      ? <i className="fas fa-check-circle text-success" />
                      : <i className="fas fa-times-circle text-danger" />}
                  </td>
                  <td>{this.convertTypeToProducts(user.type).join(',')}</td>
                  <td>{user.usage}/{user.used}/{user.limit}</td>
                  <td>{user.remark}</td>
                  <td>
                    <Link to={`/licence/logs/user/${user.name}`}>
                      <i className="fas fa-external-link-alt" />
                    </Link>
                  </td>
                </tr>);
              })
              }</tbody>
            </table>
          </div>}
      </div>
    </div>);
  }
}
function mapListStateToProps(state) {
  const users = getUserList(state);
  const version = getVersion(state);
  const {loading, version_value, version_editing, view_disabled} = getUserListViewState(state);
  return {version, users, loading, version_value, version_editing, view_disabled};
}
const actionsListFactory = {
  updateUserListView,
  callSetVersion: API.setVersion,
  callGetVersion: API.getVersion,
  callReloadUsers: API.reloadUsers,
};
const UserListComponent = connect(mapListStateToProps, actionsListFactory)(UserList);

export default class UserComponent extends PureComponent {
  render() {
    return (<React.Fragment>
      <Route path="/licence" exact component={UserListComponent} />
      <Route path="/licence/user/:username?" component={UserFormComponent} />
      <Route path="/licence/token/:username" component={ConfirmTokenComponent} />
      <Route path="/licence/logs/user/:username" component={LogListComponent} />
      <Route path="/licence/requester/device/:device" component={RequesterListComponent} />
    </React.Fragment>);
  }
}
