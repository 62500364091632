import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Route, withRouter, Link } from 'react-router-dom';
import { updateAdminListView, updateAdminFormView } from './redux/actions';
import { getAdminList, getAdminFormViewState, getAdminListViewState, makeAdminSelector } from './redux/selectors';
import * as API from './api';

class AdminForm extends PureComponent {
  componentDidMount() {
    const {admin, updateAdminFormView} = this.props;
    updateAdminFormView({formData: {'enabled': 1, ...admin}});
  }

  onSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const {admin, formData, callCreateAdmin, callUpdateAdmin, history} = this.props;
    const data = {...admin, ...formData};
    const res = !!admin ? callUpdateAdmin(data) : callCreateAdmin(data);
    res.then(() => history.push('/licence/admins'));
  };

  onDelete = () => {
    const {admin, callDeleteAdmin, history} = this.props;
    callDeleteAdmin(admin).then(() => history.push('/licence/admins'));
  };

  onChange = (event) => {
    const name = event.target.name;
    const {formData, updateAdminFormView} = this.props;
    const value = name === 'enabled' ? (event.target.checked ? 1 : 0) : event.target.value;
    updateAdminFormView({'formData': {...formData, [name]: value}})
  };

  onCancel = () => this.props.history.goBack();

  render() {
    const {admin, formData: data} = this.props;
    const nameEditable = !!admin;
    const checked = data.enabled === 1;
    return (<div className="row no-gutters"><div className="card user-card col-lg-6 col-sm-8">
      <div className="card-header">
        Admin Information
      </div>
      <div className="card-body">
        <form onSubmit={this.onSubmit}>
          <div className="form-group">
            <label htmlFor="nameInput">Name</label>
            <input type="text" {...(nameEditable && {readOnly: true})} className="form-control"
                   id="nameInput" onChange={this.onChange} name="username" value={data.username || ''} required
                   autoCapitalize="none" autoCorrect="off" autoComplete="off" />
          </div>
          {!nameEditable && <div className="form-group">
            <label htmlFor="passwordInput">Password</label>
            <input type="text" className="form-control" name="password" id="passwordInput"
                   value={data.password || ''} onChange={this.onChange} required
                   autoCapitalize="none" autoCorrect="off" autoComplete="off" />
          </div>}
          <div className="form-group">
            <div className="custom-control custom-switch">
              <input type="checkbox" className="custom-control-input" onChange={this.onChange}
                     name="enabled" {...{checked}} id="enabledCheckbox" />
              <label className="custom-control-label" htmlFor="enabledCheckbox">Enabled</label>
            </div>
          </div>
          <div>
            <button type="submit" className="btn btn-primary mr-2">Save</button>
            {nameEditable && <button type="button" className="btn btn-outline-danger mr-2" onClick={this.onDelete}>Delete</button>}
            <button type="button" className="btn btn-outline-secondary" onClick={this.onCancel}>Cancel</button>
          </div>
        </form>
      </div>
    </div></div>);
  }
}
function mapFormStateToPropsFactory() {
  const getAdminSelector = makeAdminSelector();
  return function(state, {match}) {
    const name = decodeURIComponent(match.params.username);
    const admin = getAdminSelector(state)(name);
    const {formData} = getAdminFormViewState(state);
    return {admin, formData};
  }
}
const actionsFormFactory = {
  updateAdminFormView,
  callCreateAdmin: API.createAdmin,
  callUpdateAdmin: API.updateAdmin,
  callDeleteAdmin: API.deleteAdmin,
};
const AdminFormComponent = withRouter(connect(mapFormStateToPropsFactory, actionsFormFactory)(AdminForm));

class AdminList extends PureComponent {
  componentDidMount() {
    const {callReloadAdmins} = this.props;
    callReloadAdmins();
  }

  render() {
    const {admins} = this.props;
    return (<div className="card">
      <div className="card-header">
        <Link className="btn btn-primary" to="/licence/admin">
          <i className="fas fa-plus-circle mr-2" />Admin
        </Link>
      </div>
      <div className="card-body">
        <div className="table-responsive-sm">
          <table className="table text-nowrap">
            <thead className="thead-light">
            <tr>
              <th scope="col">#</th>
              <th scope="col">Username</th>
              <th scope="col">Enabled</th>
            </tr>
            </thead>
            <tbody>{admins.map((admin, idx) => {
              return (<tr key={admin.username}>
                <td>{idx+1}</td>
                <td>
                  <Link className="btn btn-link p-0 border-0 align-baseline text-decoration-none" to={`/licence/admin/${admin.username}`}>
                    {admin.username}
                  </Link>
                </td>
                <td>
                  {admin.enabled === 1
                    ? <i className="fas fa-check-circle text-success" />
                    : <i className="fas fa-times-circle text-danger" />}
                </td>
              </tr>);
            })
            }</tbody>
          </table>
        </div>
      </div>
    </div>);
  }
}
function mapListStateToProps(state) {
  const admins = getAdminList(state);
  return {admins};
}
const actionsListFactory = {
  updateAdminListView,
  callReloadAdmins: API.reloadAdmins,
};
const AdminListComponent = withRouter(connect(mapListStateToProps, actionsListFactory)(AdminList));

export default function (props) {
  return (<React.Fragment>
    <Route path="/licence/admins" component={AdminListComponent} />
    <Route path="/licence/admin/:username?" component={AdminFormComponent} />
  </React.Fragment>);
}
