import * as Actions from '../actionTypes';

const initialState = {
  users: [],
  version: '',
  viewStates: {
    ListView: {
      loading: false,
      version_editing: false,
      version_value: '',
      view_disabled: false,
    },
    FormView: {
      formData: {auto_enable: 1},
      loading: false,
    },
    ConfirmView: {
      loading1: false,
      loading2: false,
      loading3: false,
    },
  },
};

function _reloadUsers(state, action) {
  const users = action.payload;
  return {...state, users};
}

function _createUser(state, action) {
  const users = [...state.users, action.payload];
  return {...state, users};
}

function _updateUser(state, action) {
  const name = action.payload.name;
  const users = state.users.map((user) => user.name === name ? action.payload : user);
  return {...state, users};
}

function _updateListViewStates(state, action) {
  const {viewStates} = state;
  const {ListView} = viewStates;
  return {...state, viewStates: {...viewStates, ListView: {...ListView, ...action.payload}}};
}

function _updateFormViewStates(state, action) {
  const {viewStates} = state;
  const {FormView} = viewStates;
  return {...state, viewStates: {...viewStates, FormView: {...FormView, ...action.payload}}};
}

function _updateConfirmViewStates(state, action) {
  const {viewStates} = state;
  const {ConfirmView} = viewStates;
  return {...state, viewStates: {...viewStates, ConfirmView: {...ConfirmView, ...action.payload}}};
}

export default function(state = initialState, action) {
  switch (action.type) {
    case Actions.RELOAD_USERS:
      return _reloadUsers(state, action);
    case Actions.CREATE_USER:
      return _createUser(state, action);
    case Actions.UPDATE_USER:
      return _updateUser(state, action);
    case Actions.UPDATE_USER_LIST_VIEW:
      return _updateListViewStates(state, action);
    case Actions.UPDATE_USER_FORM_VIEW:
      return _updateFormViewStates(state, action);
    case Actions.UPDATE_USER_CONFIRM_VIEW:
      return _updateConfirmViewStates(state, action);
    case Actions.SET_VERSION:
      return {...state, version: action.payload};
    default:
      return state;
  }
}
