import memoizeOne from 'memoize-one';
import { createSelector } from 'reselect';

export function getNavIndex(store) {
  return store.root.navIndex;
}

export function getAdminList(store) {
  const adminStore = store.admin;
  return adminStore.admins;
}

export function getAdminListViewState(store) {
  const adminStore = store.admin;
  return adminStore.viewStates.ListView;
}

export function getAdminFormViewState(store) {
  const adminStore = store.admin;
  return adminStore.viewStates.FormView;
}

export const makeAdminSelector = () => createSelector(
  getAdminList,
  (admins) => memoizeOne((username) => admins.find((admin) => admin.username === username))
);

export function getUserList(store) {
  const userStore = store.user;
  return userStore.users;
}

export function getVersion(store) {
  const userStore = store.user;
  return userStore.version;
}

export function getUserListViewState(store) {
  const userStore = store.user;
  return userStore.viewStates.ListView;
}

export function getUserFormViewState(store) {
  const userStore = store.user;
  return userStore.viewStates.FormView;
}

export function getUserConfirmViewState(store) {
  const userStore = store.user;
  return userStore.viewStates.ConfirmView;
}

export const makeUserSelector = () => createSelector(
  getUserList,
  (users) => memoizeOne((name) => users.find((user) => user.name === name))
);

export function getLogList(store) {
  const loggingStore = store.logging;
  return loggingStore.logs;
}

export function getDeviceList(store) {
  const loggingStore = store.logging;
  return loggingStore.devices;
}

export function getAlertList(store) {
  const loggingStore = store.logging;
  return loggingStore.alerts;
}

export function getLogListViewState(store) {
  const loggingStore = store.logging;
  return loggingStore.viewStates.ListView;
}

export function getRequesterList(store) {
  const reqStore = store.requester;
  return reqStore.requesters;
}

export function getRequesterLoading(store) {
  const reqStore = store.requester;
  return reqStore.loading;
}
