import * as Actions from '../actionTypes';
import moment from 'moment';

const initialState = {
  logs: [],
  devices: [],
  alerts: [],
  viewStates: {
    ListView: {
      loading: false,
      from_date: moment().startOf('year'),//moment().subtract(1, 'months'),
      to_date: moment(),
      tab_index: 0,
    },
  },
};

function _reloadLogs(state, action) {
  const logs = action.payload;
  return {...state, logs};
}

function _reloadDevices(state, action) {
  const devices = action.payload;
  return {...state, devices};
}

function _reloadAlerts(state, action) {
  const alerts = action.payload;
  return {...state, alerts};
}

function _updateListViewStates(state, action) {
  const {viewStates} = state;
  const {ListView} = viewStates;
  return {...state, viewStates: {...viewStates, ListView: {...ListView, ...action.payload}}};
}

export default function(state = initialState, action) {
  switch (action.type) {
    case Actions.RELOAD_LOGS:
      return _reloadLogs(state, action);
    case Actions.RELOAD_DEVICES:
      return _reloadDevices(state, action);
    case Actions.RELOAD_ALERTS:
      return _reloadAlerts(state, action);
    case Actions.UPDATE_LOG_LIST_VIEW:
      return _updateListViewStates(state, action);
    default:
      return state;
  }
}
