import { NetWork, alertNetworkError } from './common';
import * as Actions from './redux/actions';

export const reloadAdmins = () => (dispatch) => {
  const url = '/licence/api/admins';
  return NetWork.get(
    url, {},
    (list) => dispatch(Actions.reloadAdmins(list)),
    (error) => alertNetworkError(error)
  );
};

export const createAdmin = (admin) => (dispatch) => {
  const url = '/licence/api/admin';
  return NetWork.post(
    url, admin,
    () => dispatch(Actions.createAdmin(admin)),
    (error) => alertNetworkError(error)
  );
};

export const updateAdmin = (admin) => (dispatch) => {
  const url = '/licence/api/admin/enable';
  return NetWork.put(
    url, admin,
    () => dispatch(Actions.updateAdmin(admin)),
    (error) => alertNetworkError(error)
  );
};

export const changePassword = (oldPassWd, newPasswd) => {
  const url = '/licence/api/admin/passwd';
  return NetWork.put(
    url, {"old_passwd": oldPassWd, "new_passwd": newPasswd},
    (res) => Promise.resolve(res),
    (error) => alertNetworkError(error)
  );
};

export const SendTokenMail = (name, type) => {
  const url = '/licence/api/app/mail/token';
  const query = {name, type};
  return NetWork.get(
    url, query,
    (res) => Promise.resolve(res),
    (error) => alertNetworkError(error)
  );
};

export const deleteAdmin = (admin) => (dispatch) => {
  const url = `/licence/api/admin/${admin.username}`;
  return NetWork.delete(
    url,
    () => dispatch(Actions.deleteAdmin(admin)),
    (error) => alertNetworkError(error)
  );
};

export const getVersion = () => (dispatch) => {
  const url = '/licence/api/app/version';
  return NetWork.get(
    url, {},
    (res) => dispatch(Actions.setVersion(res.version)),
    (error) => alertNetworkError(error)
  );
};

export const setVersion = (version) => (dispatch) => {
  const url = '/licence/api/app/version';
  return NetWork.put(
    url, {version},
    () => dispatch(Actions.setVersion(version)),
    (error) => alertNetworkError(error)
  );
};

export const reloadUsers = () => (dispatch) => {
  const url = '/licence/api/app/users';
  return NetWork.get(
    url, {},
    (list) => dispatch(Actions.reloadUsers(list)),
    (error) => alertNetworkError(error)
  );
};

export const createUser = (user) => (dispatch) => {
  const url = '/licence/api/app/user';
  return NetWork.post(
    url, user,
    (res) => dispatch(Actions.createUser(res)),
    (error) => alertNetworkError(error)
  );
};

export const updateUser = (user) => (dispatch) => {
  const url = '/licence/api/app/user';
  return NetWork.put(
    url, user,
    (res) => dispatch(Actions.updateUser(res)),
    (error) => alertNetworkError(error)
  );
};

export const updateUserToken = (user) => (dispatch) => {
  const url = '/licence/api/app/usertoken';
  return NetWork.put(
    url, user,
    (res) => dispatch(Actions.updateUser(res)),
    (error) => alertNetworkError(error)
  );
};

export const reloadLogs = (name) => (dispatch) => {
  const url = '/licence/api/app/logging';
  return NetWork.get(
    url, {user: name},
    (res) => dispatch(Actions.reloadLogs(res)),
    (error) => alertNetworkError(error)
  );
};

export const reloadDevices = (name) => (dispatch) => {
  const url = '/licence/api/app/devices';
  return NetWork.get(
    url, {user: name},
    (res) => dispatch(Actions.reloadDevices(res)),
    (error) => alertNetworkError(error)
  );
};

export const reloadAlerts = (name) => (dispatch) => {
  const url = '/licence/api/app/alerts';
  return NetWork.get(
    url, {user: name},
    (res) => dispatch(Actions.reloadAlerts(res)),
    (error) => alertNetworkError(error)
  );
};

export const reloadRequesters = (device) => (dispatch) => {
  const url = '/licence/api/app/requesters';
  return NetWork.get(
    url, {device},
    (res) => dispatch(Actions.reloadRequesters(res)),
    (error) => alertNetworkError(error)
  );
};
