import React, { Component } from 'react';
import { Route, withRouter } from 'react-router-dom';
import { changePassword } from "./api";

class Password extends Component {
  constructor(props) {
    super(props);
    this.form = React.createRef();
    this.state = {old_passwd:'', new_passwd: '', confirm: '', valid: true};
  }

  onChange = (event) => {
    const {name, value} = event.target;
    this.setState({[name]: value});
  };

  onCancel = () => this.props.history.goBack();

  onSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const {old_passwd, new_passwd, confirm} = this.state;
    let valid = this.form.current.checkValidity();
    valid = valid && new_passwd === confirm;
    if (valid) {
      changePassword(old_passwd, new_passwd).then(() => this.props.history.push('/licence'));
    } else {
      this.setState({valid});
    }
  };

  render() {
    return (<div className="row no-gutters"><div className="card user-card col-lg-6 col-sm-8">
      <div className="card-header">
        Change your password
      </div>
      <div className="card-body">
        <form noValidate ref={this.form} onSubmit={this.onSubmit}
              className={this.state.valid ? "need-validated" : "was-validated"}>
          <div className="form-group">
            <label htmlFor="old_passwd">Old Password</label>
            <input type="password" className="form-control" name="old_passwd" id="old_passwd"
                   value={this.state.old_passwd} onChange={this.onChange} required />
            <div className="invalid-feedback">Password is required</div>
          </div>
          <div className="form-group">
            <label htmlFor="new_passwd">New Password</label>
            <input type="password" className="form-control" name="new_passwd" id="new_passwd"
                   value={this.state.new_passwd} onChange={this.onChange} required />
            <div className="invalid-feedback">Password is required</div>
          </div>
          <div className="form-group">
            <label htmlFor="confirm">Confirm</label>
            <input type="password" className="form-control" name="confirm" id="confirm"
                   value={this.state.confirm} onChange={this.onChange} required pattern={this.state.new_passwd} />
            <div className="invalid-feedback">Password is not matching</div>
          </div>
          <div>
            <button type="submit" className="btn btn-primary mr-2">Submit</button>
            <button type="button" className="btn btn-outline-secondary" onClick={this.onCancel}>Cancel</button>
          </div>
        </form>
      </div>
    </div></div>);
  }
}

export default function (props) {
  return (<React.Fragment>
    <Route path="/licence/password" component={Password} />
  </React.Fragment>);
}
