import { combineReducers } from "redux";
import * as Actions from '../actionTypes';
import user from './user';
import logging from './logging';
import admin from './admin';
import requester from './requester';

const initialState = {
  navIndex: 'Home',
};

function root(state = initialState, action) {
  switch(action.type) {
    case Actions.UPDATE_NAV_INDEX:
      return {...state, navIndex: action.payload};
    default:
      return state;
  }
}
export default combineReducers({
  root,
  admin,
  user,
  logging,
  requester,
});
