import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getNavIndex } from './redux/selectors';
import { updateNavIndex } from './redux/actions';
import './_App.scss';
import UserComponent from './User';
import AdminComponent from './Admin';
import PasswordComponent from './Password';
import { getCurrentUser, logout } from './common';

class App extends PureComponent {
  onNavClick = (event) => {
    let name = event.target.name;
    const {history, updateNavIndex} = this.props;
    switch (name) {
      case 'Home':
        history.push('/licence');
        break;
      case 'Admin':
        history.push('/licence/admins');
        break;
      case 'Password':
        history.push('/licence/password');
        break;
      case 'Logout':
        name = 'Home';
        logout();
        history.push('/licence/login');
        break;
      default:
        break;
    }
    updateNavIndex(name);
  };

  render() {
    const {navIndex} = this.props;
    const user = getCurrentUser();
    const names = ['Home', 'Admin', 'Password', 'Logout'];
    return (
      <main id="content" className={``}>
        <nav className="navbar navbar-expand-lg navbar-dark bg-primary">
          <span className="navbar-brand mb-0 h1">High+ Licence</span>
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup"
                  aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
            <div className="navbar-nav">
              {names.map((name) => {
                const disabled = name === 'Admin' ? (user !== 'sysadmin' ? ' disabled' : '') : '';
                const className = `nav-link btn btn-link${navIndex === name ? " active" : ""}${disabled}`;
                return <button key={name} name={name} className={className} onClick={this.onNavClick}>{name}</button>;
              })}
            </div>
          </div>
        </nav>
        <div className="content-container mt-5">
          <UserComponent />
          <AdminComponent />
          <PasswordComponent />
        </div>
        <div className="alert-container" />
      </main>
    );
  }
}

function mapStateToProps(state) {
  const navIndex = getNavIndex(state);
  return {navIndex};
}
const actionsFactory = {
  updateNavIndex,
};
export default withRouter(connect(mapStateToProps, actionsFactory)(App));
