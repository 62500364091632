import 'whatwg-fetch';

const $ = window.$;

//export const HOST = 'http://10.0.0.10:8000';
export const HOST = '';
const cors_mode = 'cors'; // cors

export function alertMessage(message, contextual = 'alert-warning') {
  const alertHtml = `<div class="alert ${contextual} alert-dismissible fade show" role="alert">
    ${message}
    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>`;
  $('.alert-container').append(alertHtml);
  document.body.scrollTop = document.documentElement.scrollTop = 0;
}

export function alertNetworkError(exception) {
  console.log(exception);
  if (exception && typeof exception === 'object') {
    const {errcode, errmsg, status, content} = exception;
    switch (status) {
      case 400:
        alertMessage(`ErrorCode:${errcode} ${errmsg}`);
        break;
      default:
        alertMessage(`HttpCode:${status} ${content}`);
        break;
    }
  } else {
    alertMessage(exception);
  }
  return Promise.reject(exception);
}

export function getQueryString(obj) {
  const query = Object.entries(obj || {}).reduce((acc, [key, value]) => {
    value != null && acc.push(`${key}=${value}`);
    return acc;
  }, []);
  return query.length > 0 ? '?' + query.join('&') : '';
}

export function login(username, password) {
  const opts = {
    method: 'POST',
    body: JSON.stringify({username, password}),
    credentials: 'omit',
    mode: cors_mode,
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    }
  };
  return fetch(`${HOST}/licence/api/login`, opts).then((resp) => {
    if (resp.ok) return resp.json().then(({success, token}) => {
      window.localStorage.setItem('CurrentUser', username);
      window.localStorage.setItem('Authorization', token);
    });
    const http = {
      status: resp.status,
      statusText: resp.statusText
    };
    if (resp.status === 400) return resp.json().then((data) => Promise.reject({...data, ...http}));
    return resp.text().then((content) => Promise.reject({content, ...http}));
  });
}

export function logout() {
  window.localStorage.removeItem('CurrentUser');
  window.localStorage.removeItem('Authorization');
}

export const getToken = () => {
  const username = window.localStorage.getItem('CurrentUser');
  const token = window.localStorage.getItem('Authorization');
  return `Bearer ${username}.${token}`;
};

export const getCurrentUser = () => {
  return window.localStorage.getItem('CurrentUser');
};

class Connection {
  constructor(url, init, success, failure) {
    this.url = url;
    this.init = init;
    this.success = success;
    this.failure = failure;
  }

  handleResponse = (response) => {
    const contentType = response.headers.get('content-type');
    if (contentType.includes('application/json')) return response.json();
    if (contentType.includes('text/html') || contentType.includes('text/plain')) return response.text();
    return Promise.reject(`Content-Type: ${contentType} have not supported.`);
  };

  handleException = (error) => {
    console.log(error)
    const msg = `Networking error: ${error.message}`;
    alertMessage(msg, 'alert-danger');
    return Promise.reject(error);
  };

  send = (authRetry = true) => fetch(this.url, this.init).then((response) => {
    let promise = this.handleResponse(response);
    if (response.ok) {
      promise = this.success ? promise.then((data) => this.success(data)) : promise;
    } else {
      const http = {
        status: response.status,
        statusText: response.statusText
      };
      switch (response.status) {
        case 400: // application error
          promise = promise.then((data) => Promise.reject({...data, ...http}));
          break;
        case 401: // unauthorized
          window.location.href = '/licence/login';
          break;
        case 403: // access denied
        case 500: // system error
        default:
          promise = promise.then((content) => Promise.reject({content, ...http}));
          break;
      }
    }
    if (this.failure) promise = promise.catch((data) => (this.failure(data), Promise.reject(data)));
    return promise;
  }, this.handleException);
}

export const NetWork = Object.freeze({
  get:  (baseurl, query, success, failure) => {
    const url = `${HOST}${baseurl}` + getQueryString(query);
    const init = {
      method: 'GET',
      credentials: 'omit',
      mode: cors_mode,
      redirect: 'follow',
      cache: 'no-cache',
      headers: {
        'Accept': 'application/json',
        'Authorization': getToken(),
      },
    };
    const conn = new Connection(url, init, success, failure);
    return conn.send();
  },
  post: (url, body, success, failure) => {
    const init = {
      method: 'POST',
      body: JSON.stringify(body),
      credentials: 'omit',
      mode: cors_mode,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': getToken(),
      }
    };
    const conn = new Connection(`${HOST}${url}`, init, success, failure);
    return conn.send();
  },
  delete: (url, success, failure) => {
    const init= {
      method: 'DELETE',
      credentials: 'omit',
      mode: cors_mode,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': getToken(),
      }
    };
    const conn = new Connection(`${HOST}${url}`, init, success, failure);
    return conn.send();
  },
  put: (url, body, success, failure) => {
    const init = {
      method: 'PUT',
      body: JSON.stringify(body),
      credentials: 'omit',
      mode: cors_mode,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': getToken(),
      }
    };
    const conn = new Connection(`${HOST}${url}`, init, success, failure);
    return conn.send();
  },
});

