import React, { Component } from 'react';
import { login } from './common';

const $ = window.$;

export default class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      valid: true,
      showError: false,
      resultError: '',
    };
    this.form = React.createRef();
  }

  onSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const valid = this.form.current.checkValidity();
    if (valid) {
      const $form = $(this.form.current);
      const data = $form.serializeArray().reduce((acc, {name, value}) => (acc[name] = value, acc), {});
      login(data.name, data.password).then(() => window.location.href = '/licence').catch(({status, ...rest}) => {
        const state = {showError: true};
        if (400 === status) {
          const {errcode, errmsg} = rest;
          state.resultError = (errcode === 1 || errcode === 2) ? 'Wrong username or password' : errmsg;
        } else {
          state.resultError = `Unexpected error:${status}`;
        }
        this.setState(state);
      })
    } else {
      this.setState({valid: false});
    }
  };

  onFormBlur = () => this.setState({showError: false});

  render() {
    const {valid, showError, resultError} = this.state;
    return (
      <div className="bg-white">
        <div id="login-card" className="card">
          <div className="card-body">
            <h5 className="card-title text-center">High+ Licence</h5>
            <div className="mt-5">
              <form className={valid ? "need-validated" : "was-validated"} noValidate ref={this.form} onSubmit={this.onSubmit} onBlur={this.onFormBlur}>
                <div className="form-group">
                  <input name="name" required type="text" className="form-control" id="account" placeholder="Input your username" />
                  <div className="invalid-feedback">Username is required</div>
                </div>
                <div className="form-group">
                  <input name="password" required type="password" className="form-control" id="password" placeholder="Input your password" />
                  <div className="invalid-feedback">Password is required</div>
                </div>
                {showError && <span className="text-danger">{resultError}</span>}
                <button type="submit" className="mt-2 btn btn-primary btn-block">Login</button>
              </form>
            </div>
            <div className="card-body text-muted text-center mt-2">
              591Lab©2022
            </div>
          </div>
        </div>
      </div>
    );
  }
}

