// root actions
export const UPDATE_NAV_INDEX = 'UPDATE_NAV_INDEX';

// admin actions
export const RELOAD_ADMINS = 'RELOAD_ADMINS';
export const CREATE_ADMIN = 'CREATE_ADMIN';
export const UPDATE_ADMIN = 'UPDATE_ADMIN';
export const DELETE_ADMIN = 'DELETE_ADMIN';
export const UPDATE_ADMIN_LIST_VIEW = 'UPDATE_ADMIN_LIST_VIEW';
export const UPDATE_ADMIN_FORM_VIEW = 'UPDATE_ADMIN_FORM_VIEW';

// user actions
export const RELOAD_USERS = 'RELOAD_USERS';
export const CREATE_USER = 'CREATE_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_LIST_VIEW = 'UPDATE_USER_LIST_VIEW';
export const UPDATE_USER_FORM_VIEW = 'UPDATE_USER_FORM_VIEW';
export const UPDATE_USER_CONFIRM_VIEW = 'UPDATE_USER_CONFIRM_VIEW';

export const SET_VERSION = 'SET_VERSION';

// logging actions
export const RELOAD_LOGS = 'RELOAD_LOGS';
export const RELOAD_ALERTS = 'RELOAD_ALERTS';
export const RELOAD_DEVICES = 'RELOAD_DEVICES';
export const UPDATE_LOG_LIST_VIEW = 'UPDATE_LOG_LIST_VIEW';

// requester actions
export const RELOAD_DEVICE_REQUESTERS = 'RELOAD_DEVICE_REQUESTERS';
export const UPDATE_REQUESTERS_LOADING = 'UPDATE_REQUESTERS_LOADING';
