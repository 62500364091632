import * as Actions from '../actionTypes';

const initialState = {
  loading: false,
  requesters: []
};

function _reloadRequesters(state, action) {
  const requesters = action.payload;
  return {...state, requesters};
}

function _updateRequesterLoading(state, action) {
  const loading = action.payload;
  return {...state, loading};
}

export default function(state = initialState, action) {
  switch (action.type) {
    case Actions.RELOAD_DEVICE_REQUESTERS:
      return _reloadRequesters(state, action);
    case Actions.UPDATE_REQUESTERS_LOADING:
      return _updateRequesterLoading(state, action);
    default:
      return state;
  }
}
