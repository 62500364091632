import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import moment from 'moment';
import { updateLogListView } from './redux/actions';
import { getLogList, getDeviceList, getAlertList, getLogListViewState } from './redux/selectors';
import * as API from './api';
import { alertMessage } from './common';

const XLSX = window.XLSX;

function getCityOfAddress(address) {
  const array = address.split(',');
  return array.length ? array[0] : '';
}

function getCountryCode(address) {
  const idx = address.indexOf(',');
  if(idx === -1) return '';
  const sub = address.substring(idx);
  const iStart = sub.indexOf('[');
  const iEnd = sub.indexOf(']');
  if(iStart !== -1 && iEnd!== -1 && iEnd-iStart > 1) {
    return sub.substring(iStart+1, iEnd);
  }
  return '';
}

class AlertTable extends PureComponent {
  componentDidMount() {
    const {name, callReloadAlerts} = this.props;
    this.props.updateLogListView({loading: true});
    callReloadAlerts(name).finally(() => this.props.updateLogListView({loading: false}));
  }

  render() {
    const {loading, from_date, to_date, alerts} = this.props;
    return (loading ?
      <div className="align-items-center d-flex flex-column justify-content-center vh-100">
        <div className="spinner-border text-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div> :
      <div className="table-responsive-md"><table className="table word-nowrap-sm">
      <thead className="thead-light">
      <tr>
        <th scope="col">#</th>
        <th scope="col">Date</th>
        <th scope="col">DeviceId</th>
        <th scope="col">Message</th>
      </tr>
      </thead>
      <tbody>{alerts.filter((alert) => moment(alert.date).isBetween(from_date, to_date, 'day', '[]'))
        .map((alert, idx) => {
          const date = moment(alert.date).format('llll');
          return (<tr key={alert.date}>
            <td>{idx+1}</td>
            <td>{date}</td>
            <td>{alert.device}</td>
            <td>{alert.message}</td>
          </tr>);
        })
      }</tbody>
    </table></div>);
  }
}

class DeviceTable extends PureComponent {
  componentDidMount() {
    const {name, callReloadDevices} = this.props;
    this.props.updateLogListView({loading: true});
    callReloadDevices(name).finally(() => this.props.updateLogListView({loading: false}));
  }

  render() {
    const {loading, from_date, to_date, devices} = this.props;
    return (loading ?
      <div className="align-items-center d-flex flex-column justify-content-center vh-100">
        <div className="spinner-border text-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div> :
      <div className="table-responsive-md"><table className="table word-nowrap-sm">
      <thead className="thead-light">
      <tr>
        <th scope="col">#</th>
        <th scope="col">Date</th>
        <th scope="col">DeviceId</th>
        <th scope="col">Used</th>
        <th scope="col">Duration</th>
        <th scope="col">IP</th>
        <th scope="col">City</th>
        <th scope="col">Host</th>
        <th scope="col">OS User</th>
        <th scope="col">OS</th>
      </tr>
      </thead>
      <tbody>{devices.filter((device) => moment(device.date).isBetween(from_date, to_date, 'day', '[]'))
        .map((device, idx) => {
          const date = moment(device.date).format('llll');
          const hours = Math.floor(device.duration / 60);
          const minutes = device.duration % 60;
          const duration = hours > 0 ? `${hours}h ${minutes}m` : `${minutes}m`;
          return (<tr key={device.date}>
            <td>{idx+1}</td>
            <td>{date}</td>
            <td><Link to={`/licence/requester/device/${device.id}`}>{device.id}</Link></td>
            <td>{device.used ? 'True' : 'False'}</td>
            <td>{duration}</td>
            <td>{device.ip}</td>
            <td>{getCityOfAddress(device.address)}</td>
            <td>{device.machine}</td>
            <td>{device.op}</td>
            <td>{device.os}</td>
          </tr>);
        })
      }</tbody>
    </table></div>);
  }
}

class DeviceOutputBtn extends PureComponent {
  outputToXls = () => {
    const {from_date, to_date, devices} = this.props;
    const out = devices.filter((device) => moment(device.date).isBetween(from_date, to_date, 'day', '[]'))
      .map((device) => ({
        'Date': moment(device.date).format('YYYY/MM/DD'),
        'ID': device.id,
        'Duration': device.duration,
        'IP': device.ip,
        'City': getCityOfAddress(device.address),
        'Country': getCountryCode(device.address),
        'Host': device.machine,
        'OS User': device.op,
        'OS': device.os
      }));
    //console.log(out);
    if (out.length === 0) {
      alertMessage("No rows are in the range!");
      return;
    }
    const ws = XLSX.utils.json_to_sheet(out, {header:["Date","ID","Duration","IP","City","Country","Host","OS User","OS"], cellDates: true});
    ws['!cols'] = [ { wch: 12 }, { wch: 12 }, { wch: 12 }, { wch: 20 }, { wch: 30 }, { wch: 7 }, { wch: 20 }, { wch: 20 }, { wch: 50 } ];
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, ws, "Sheet1");
    const filename = `${devices[0].user}_${from_date.format('YYYY-MM-DD')}_${to_date.format('YYYY-MM-DD')}.xlsx`;
    XLSX.writeFile(workbook, filename);
  };

  render() {
    return (
      <button className="btn btn-outline-dark" onClick={this.outputToXls}>
        <i className="fas fa-file-export"/>
      </button>
    );
  }
}

class LoggingTable extends PureComponent {
  componentDidMount() {
    const {name, callReloadLogs} = this.props;
    this.props.updateLogListView({loading: true});
    callReloadLogs(name).finally(() => this.props.updateLogListView({loading: false}));
  }

  render() {
    const {loading, from_date, to_date, logs} = this.props;
    return (loading ?
      <div className="align-items-center d-flex flex-column justify-content-center vh-100">
        <div className="spinner-border text-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div> :
      <div className="table-responsive-md"><table className="table word-nowrap-sm">
      <thead className="thead-light">
      <tr>
        <th scope="col">#</th>
        <th scope="col">Date</th>
        <th scope="col">Validation</th>
        <th scope="col">Reason</th>
        <th scope="col">IP</th>
        <th scope="col">City</th>
        <th scope="col">Host</th>
        <th scope="col">OS User</th>
        <th scope="col">OS</th>
      </tr>
      </thead>
      <tbody>{logs.filter((log) => moment(log.date).isBetween(from_date, to_date, 'day', '[]'))
        .map((logging, idx) => {
        const date = moment(logging.date).format('llll');
        let reason = '';
        switch (logging.result) {
          case 4:
            reason = 'Invalid key';
            break;
          case 5:
            reason = 'User disabled';
            break;
          case 6:
            reason = 'Invalid version';
            break;
          case 7:
            reason = 'Ineffective';
            break;
          case 8:
            reason = 'Expired';
            break;
          default:
            break;
        }
        return (<tr key={logging.date}>
          <td>{idx+1}</td>
          <td>{date}</td>
          <td>
            {logging.result === 0
              ? <i className="fas fa-check-circle text-success" />
              : <i className="fas fa-times-circle text-danger" />}
          </td>
          <td>{reason}</td>
          <td>{logging.ip}</td>
          <td>{getCityOfAddress(logging.address)}</td>
          <td>{logging.machine}</td>
          <td>{logging.op}</td>
          <td>{logging.os}</td>
        </tr>);
      })
      }</tbody>
    </table></div>);
  }
}

class LoggingOutputBtn extends PureComponent {
  outputToXls = () => {
    const {from_date, to_date, logs} = this.props;
    const out = logs.filter((log) => moment(log.date).isBetween(from_date, to_date, 'day', '[]') && log.result === 0)
      .map((log) => ({
        'Date': moment(log.date).format('YYYY/MM/DD'),
        'IP': log.ip,
        'City': getCityOfAddress(log.address),
        'Country': getCountryCode(log.address),
        'Host': log.machine,
        'OS User': log.op,
        'OS': log.os
      }));
    //console.log(out);
    if (out.length === 0) {
      alertMessage("No rows are in the range!");
      return;
    }
    const ws = XLSX.utils.json_to_sheet(out, {header:["Date","IP","City","Country","Host","OS User","OS"], cellDates: true});
    ws['!cols'] = [ { wch: 12 }, { wch: 20 }, { wch: 30 }, { wch: 7 }, { wch: 20 }, { wch: 20 }, { wch: 50 } ];
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, ws, "Sheet1");
    const filename = `${logs[0].user}_${from_date.format('YYYY-MM-DD')}_${to_date.format('YYYY-MM-DD')}.xlsx`;
    XLSX.writeFile(workbook, filename);
  };

  render() {
    return (
      <button className="btn btn-outline-dark" onClick={this.outputToXls}>
        <i className="fas fa-file-export"/>
      </button>
    );
  }
}

class LogList extends PureComponent {
  onFromChange = (event) => {
    const date = event.target.value;
    const from_date = moment(date);
    this.props.updateLogListView({from_date});
  };

  onToChange = (event) => {
    const date = event.target.value;
    const to_date = moment(date);
    this.props.updateLogListView({to_date});
  };

  onSwitchTab = (event) => {
    const {tab_index, updateLogListView} = this.props;
    const name = event.target.name;
    let index = 0;
    if (name === "logTab") index = 0;
    if (name === "usedTab") index = 1;
    if (name === "alertTab") index = 2;
    if (tab_index !== index) {
      updateLogListView({tab_index: index})
    }
  };

  render() {
    const {
      name, loading, from_date, to_date, tab_index,
      logs, devices, alerts, updateLogListView,
      callReloadLogs, callReloadDevices, callReloadAlerts
    } = this.props;
    return (<div>
      <div className="d-flex justify-content-between mb-3">
        <ul className="nav nav-pills ml-3">
          <li className="nav-item">
            <button name="logTab" className={"btn nav-link" + (tab_index === 0 ? " active" : "")} onClick={this.onSwitchTab}>
              Installed Machines
            </button>
          </li>
          <li className="nav-item">
            <button name="usedTab" className={"btn nav-link" + (tab_index === 1 ? " active" : "")} onClick={this.onSwitchTab}>
              Used Machines
            </button>
          </li>
          <li className="nav-item">
            <button name="alertTab" className={"btn nav-link" + (tab_index === 2 ? " active" : "")} onClick={this.onSwitchTab}>
              Alerts
            </button>
          </li>
        </ul>
        <form className="form-inline mr-3">
          <div className="form-group mr-3">
            <label className="mr-2" htmlFor="startInput">From</label>
            <input type="date" className="form-control" id="startInput" onChange={this.onFromChange}
                   name="from_date" value={from_date.format('YYYY-MM-DD')} required />
          </div>
          <div className="form-group mr-2">
            <label className="mr-2" htmlFor="endInput">To</label>
            <input type="date" className="form-control" id="endInput" onChange={this.onToChange}
                   name="to_date" value={to_date.format('YYYY-MM-DD')} required />
          </div>
          {tab_index === 0 && <LoggingOutputBtn from_date={from_date} to_date={to_date} logs={logs} />}
          {tab_index === 1 && <DeviceOutputBtn from_date={from_date} to_date={to_date} devices={devices} />}
        </form>
      </div>
      {tab_index === 0 && <LoggingTable name={name} logs={logs} callReloadLogs={callReloadLogs} from_date={from_date} to_date={to_date} loading={loading} updateLogListView={updateLogListView} />}
      {tab_index === 1 && <DeviceTable name={name} devices={devices} callReloadDevices={callReloadDevices} from_date={from_date} to_date={to_date} loading={loading} updateLogListView={updateLogListView} />}
      {tab_index === 2 && <AlertTable name={name} alerts={alerts} callReloadAlerts={callReloadAlerts} from_date={from_date} to_date={to_date} loading={loading} updateLogListView={updateLogListView} />}
    </div>);
  }
}
function mapStateToProps(state, {match}) {
  const name = decodeURIComponent(match.params.username);
  let logs = name ? getLogList(state) : [];
  logs = logs.length && logs[0].user === name ? logs : [];
  let devices = name ? getDeviceList(state) : [];
  devices = devices.length && devices[0].user === name ? devices : [];
  let alerts = name ? getAlertList(state) : [];
  alerts = alerts.length && alerts[0].user === name ? alerts : [];
  const {loading, from_date, to_date, tab_index} = getLogListViewState(state);
  return {name, logs, devices, alerts, loading, from_date, to_date, tab_index};
}
const actionsFactory = {
  updateLogListView,
  callReloadLogs: API.reloadLogs,
  callReloadDevices: API.reloadDevices,
  callReloadAlerts: API.reloadAlerts,
};
export default withRouter(connect(mapStateToProps, actionsFactory)(LogList));
