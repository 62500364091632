import * as Actions from '../actionTypes';

const initialState = {
  admins: [],
  viewStates: {
    ListView: {
      loading: false,
    },
    FormView: {
      formData: {},
      loading: false,
    }
  },
};

function _reloadAdmins(state, action) {
  const admins = action.payload;
  return {...state, admins};
}

function _createAdmin(state, action) {
  const admins = [...state.admins, action.payload];
  return {...state, admins};
}

function _updateAdmin(state, action) {
  const username = action.payload.username;
  const admins = state.admins.map((admin) => admin.username === username ? action.payload : admin);
  return {...state, admins};
}

function _deleteAdmin(state, action) {
  const username = action.payload.username;
  const admins = state.admins.filter((admin) => admin.username !== username);
  return {...state, admins};
}

function _updateListViewStates(state, action) {
  const {viewStates} = state;
  const {ListView} = viewStates;
  return {...state, viewStates: {...viewStates, ListView: {...ListView, ...action.payload}}};
}

function _updateFormViewStates(state, action) {
  const {viewStates} = state;
  const {FormView} = viewStates;
  return {...state, viewStates: {...viewStates, FormView: {...FormView, ...action.payload}}};
}

export default function(state = initialState, action) {
  switch (action.type) {
    case Actions.RELOAD_ADMINS:
      return _reloadAdmins(state, action);
    case Actions.CREATE_ADMIN:
      return _createAdmin(state, action);
    case Actions.UPDATE_ADMIN:
      return _updateAdmin(state, action);
    case Actions.DELETE_ADMIN:
      return _deleteAdmin(state, action);
    case Actions.UPDATE_ADMIN_LIST_VIEW:
      return _updateListViewStates(state, action);
    case Actions.UPDATE_ADMIN_FORM_VIEW:
      return _updateFormViewStates(state, action);
    default:
      return state;
  }
}
