import * as Actions from './actionTypes';

export const updateNavIndex = (index) => ({
  type: Actions.UPDATE_NAV_INDEX,
  payload: index,
});

export const reloadAdmins = (adminList) => ({
  type: Actions.RELOAD_ADMINS,
  payload: adminList.users,
});

export const createAdmin = (admin) => ({
  type: Actions.CREATE_ADMIN,
  payload: admin,
});

export const updateAdmin = (admin) => ({
  type: Actions.UPDATE_ADMIN,
  payload: admin,
});

export const deleteAdmin = (admin) => ({
  type: Actions.DELETE_ADMIN,
  payload: admin,
});

export const updateAdminListView = (viewState) => ({
  type: Actions.UPDATE_ADMIN_LIST_VIEW,
  payload: viewState,
});

export const updateAdminFormView = (viewState) => ({
  type: Actions.UPDATE_ADMIN_FORM_VIEW,
  payload: viewState,
});

export const reloadUsers = (userList) => ({
  type: Actions.RELOAD_USERS,
  payload: userList.users,
});

export const createUser = (user) => ({
  type: Actions.CREATE_USER,
  payload: user,
});

export const updateUser = (user) => ({
  type: Actions.UPDATE_USER,
  payload: user,
});

export const setVersion = (version) => ({
  type: Actions.SET_VERSION,
  payload: version,
});

export const updateUserListView = (viewState) => ({
  type: Actions.UPDATE_USER_LIST_VIEW,
  payload: viewState,
});

export const updateUserConfirmView = (viewState) => ({
  type: Actions.UPDATE_USER_CONFIRM_VIEW,
  payload: viewState,
});

export const updateUserFormView = (viewState) => ({
  type: Actions.UPDATE_USER_FORM_VIEW,
  payload: viewState,
});

export const reloadLogs = (loggingList) => ({
  type: Actions.RELOAD_LOGS,
  payload: loggingList.logs,
});

export const reloadDevices = (loggingList) => ({
  type: Actions.RELOAD_DEVICES,
  payload: loggingList.devices,
});

export const reloadAlerts = (loggingList) => ({
  type: Actions.RELOAD_ALERTS,
  payload: loggingList.alerts,
});

export const updateLogListView = (viewState) => ({
  type: Actions.UPDATE_LOG_LIST_VIEW,
  payload: viewState,
});

export const reloadRequesters = (requesterList) => ({
  type: Actions.RELOAD_DEVICE_REQUESTERS,
  payload: requesterList.requesters,
});

export const updateRequesterLoading = (loading) => ({
  type: Actions.UPDATE_REQUESTERS_LOADING,
  payload: loading,
});
